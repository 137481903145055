export const WEEKLY_PROBLEMS = {
  "2020-08-30": "pell-numbers",
  "2020-09-06": "paths",
  "2020-09-13": "number-challenge-1",
  "2020-09-20": "making-squares",
  "2020-09-27": "logic-puzzles-1",
  "2020-10-04": "iq-challenge-1",
  "2020-10-11": "find-the-path",
  "2020-10-18": "counting-squares",
  "2020-10-25": "buffons-needle",
  "2020-11-01": "balls-and-books",
  "2020-11-08": "addition-squares",
  "2020-11-15": "adding-to-15",
  "2020-11-22": "9-dots",
  "2020-11-29": "treasure-hunt",
  "2020-12-06": "russian-multiplication",
  "2020-12-13": "river-crossing",
  "2020-12-20": "poisoned-wine-puzzle",
  "2020-12-27": "matchstick-puzzles",
  "2021-01-03": "magic-cards",
  "2021-01-10": "langfords-problem",
  "2021-01-17": "handshake-puzzles",
  "2021-01-24": "geometry-puzzle-1",
  "2021-01-31": "gabriels-problem",
  "2021-02-07": "fence-around-a-field",
  "2021-02-14": "domino-tilings",
  "2021-02-21": "collatz-conjecture",
  "2021-02-28": "coin-game",
  "2021-03-07": "coconut-trader",
  "2021-03-14": "circumference",
  "2021-03-21": "apple-teaser",
  "2021-03-28": "two-eggs",
  "2021-04-04": "special-numbers",
  "2021-04-11": "scales-and-weights",
  "2021-04-18": "picture-puzzles-1",
  "2021-04-25": "monkey-business",
  "2021-05-02": "locks-and-keys",
  "2021-05-09": "frog-party",
  "2021-05-16": "counting-chickens",
  "2021-05-23": "tic-tac-toe",
  "2021-05-30": "tic-tac-toe-with-levels",
  "2021-06-06": "tangrams",
  "2021-06-13": "secret-santa",
  "2021-06-20": "patience",
  "2021-06-27": "paper-pieces",
  "2021-07-04": "nim-related-games",
  "2021-07-11": "nim-21",
  "2021-07-18": "mastermind",
  "2021-07-25": "make-many",
  "2021-08-01": "letter-matching",
  "2021-08-08": "countdown",
  "2021-08-15": "15-game",
  "2021-08-22": "tetris-shapes",
  "2021-08-29": "konigsberg-bridges",
  "2021-09-05": "kaprekars-number",
  "2021-09-12": "diffy-squares",
  "2021-09-19": "where-on-earth",
  "2021-09-26": "sdt-riddles",
  "2021-10-03": "minimax",
  "2021-10-10": "colouring-maps",
  "2021-10-17": "bulgarian-solitaire",
  "2021-10-24": "pell-numbers",
  "2021-10-31": "paths",
  "2021-11-07": "number-challenge-1",
  "2021-11-14": "making-squares",
  "2021-11-21": "logic-puzzles-1",
  "2021-11-28": "iq-challenge-1",
  "2021-12-05": "find-the-path",
  "2021-12-12": "counting-squares",
  "2021-12-19": "buffons-needle",
  "2021-12-26": "balls-and-books",
  "2022-01-02": "addition-squares",
  "2022-01-09": "adding-to-15",
  "2022-01-16": "9-dots",
  "2022-01-23": "treasure-hunt",
  "2022-01-30": "russian-multiplication",
  "2022-02-06": "river-crossing",
  "2022-02-13": "poisoned-wine-puzzle",
  "2022-02-20": "matchstick-puzzles",
  "2022-02-27": "magic-cards",
  "2022-03-06": "langfords-problem",
  "2022-03-13": "handshake-puzzles",
  "2022-03-20": "geometry-puzzle-1",
  "2022-03-27": "gabriels-problem",
  "2022-04-03": "fence-around-a-field",
  "2022-04-10": "domino-tilings",
  "2022-04-17": "collatz-conjecture",
  "2022-04-24": "coin-game",
  "2022-05-01": "coconut-trader",
  "2022-05-08": "circumference",
  "2022-05-15": "apple-teaser",
  "2022-05-22": "two-eggs",
  "2022-05-29": "special-numbers",
  "2022-06-05": "scales-and-weights",
  "2022-06-12": "picture-puzzles-1",
  "2022-06-19": "monkey-business",
  "2022-06-26": "locks-and-keys",
  "2022-07-03": "frog-party",
  "2022-07-10": "counting-chickens",
  "2022-07-17": "tic-tac-toe",
  "2022-07-24": "tic-tac-toe-with-levels",
  "2022-07-31": "tangrams",
  "2022-08-07": "secret-santa",
  "2022-08-14": "patience",
  "2022-08-21": "paper-pieces",
  "2022-08-28": "nim-related-games",
  "2022-09-04": "nim-21",
  "2022-09-11": "mastermind",
  "2022-09-18": "make-many",
  "2022-09-25": "letter-matching",
  "2022-10-02": "countdown",
  "2022-10-09": "15-game",
  "2022-10-16": "tetris-shapes",
  "2022-10-23": "konigsberg-bridges",
  "2022-10-30": "kaprekars-number",
  "2022-11-06": "diffy-squares",
  "2022-11-13": "where-on-earth",
  "2022-11-20": "sdt-riddles",
  "2022-11-27": "minimax",
  "2022-12-04": "colouring-maps",
  "2022-12-11": "bulgarian-solitaire",
  "2022-12-18": "pell-numbers",
  "2022-12-25": "paths",
  "2023-01-01": "number-challenge-1",
  "2023-01-08": "making-squares",
  "2023-01-15": "logic-puzzles-1",
  "2023-01-22": "iq-challenge-1",
  "2023-01-29": "find-the-path",
  "2023-02-05": "counting-squares",
  "2023-02-12": "buffons-needle",
  "2023-02-19": "balls-and-books",
  "2023-02-26": "addition-squares",
  "2023-03-05": "adding-to-15",
  "2023-03-12": "9-dots",
  "2023-03-19": "treasure-hunt",
  "2023-03-26": "russian-multiplication",
  "2023-04-02": "river-crossing",
  "2023-04-09": "poisoned-wine-puzzle",
  "2023-04-16": "matchstick-puzzles",
  "2023-04-23": "magic-cards",
  "2023-04-30": "langfords-problem",
  "2023-05-07": "handshake-puzzles",
  "2023-05-14": "geometry-puzzle-1",
  "2023-05-21": "gabriels-problem",
  "2023-05-28": "fence-around-a-field",
  "2023-06-04": "domino-tilings",
  "2023-06-11": "collatz-conjecture",
  "2023-06-18": "coin-game",
  "2023-06-25": "coconut-trader",
  "2023-07-02": "circumference",
  "2023-07-09": "apple-teaser",
  "2023-07-16": "two-eggs",
  "2023-07-23": "special-numbers",
  "2023-07-30": "scales-and-weights",
  "2023-08-06": "picture-puzzles-1",
  "2023-08-13": "monkey-business",
  "2023-08-20": "locks-and-keys",
  "2023-08-27": "frog-party",
  "2023-09-03": "counting-chickens",
  "2023-09-10": "tic-tac-toe",
  "2023-09-17": "tic-tac-toe-with-levels",
  "2023-09-24": "tangrams",
  "2023-10-01": "secret-santa",
  "2023-10-08": "patience",
  "2023-10-15": "paper-pieces",
  "2023-10-22": "nim-related-games",
  "2023-10-29": "nim-21",
  "2023-11-05": "mastermind",
  "2023-11-12": "make-many",
  "2023-11-19": "letter-matching",
  "2023-11-26": "countdown",
  "2023-12-03": "15-game",
  "2023-12-10": "tetris-shapes",
  "2023-12-17": "konigsberg-bridges",
  "2023-12-24": "kaprekars-number",
  "2023-12-31": "diffy-squares",
  "2024-01-07": "where-on-earth",
  "2024-01-14": "sdt-riddles",
  "2024-01-21": "minimax",
  "2024-01-28": "colouring-maps",
  "2024-02-04": "bulgarian-solitaire",
  "2024-02-11": "pell-numbers",
  "2024-02-18": "paths",
  "2024-02-25": "number-challenge-1",
  "2024-03-03": "making-squares",
  "2024-03-10": "logic-puzzles-1",
  "2024-03-17": "iq-challenge-1",
  "2024-03-24": "find-the-path",
  "2024-03-31": "counting-squares",
  "2024-04-07": "buffons-needle",
  "2024-04-14": "balls-and-books",
  "2024-04-21": "addition-squares",
  "2024-04-28": "adding-to-15",
  "2024-05-05": "9-dots",
  "2024-05-12": "treasure-hunt",
  "2024-05-19": "russian-multiplication",
  "2024-05-26": "river-crossing",
  "2024-06-02": "poisoned-wine-puzzle",
  "2024-06-09": "matchstick-puzzles",
  "2024-06-16": "magic-cards",
  "2024-06-23": "langfords-problem"
}