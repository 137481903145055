<div class="page">
  <p>
    We hope you enjoy using the app. If you have any questions or feedback
    regarding the app please feel free to send via email to:
  </p>
  <a href="mailto:apps@c2dev.co.uk?subject=SAMI%20Maths%20Club%20App">
    <button mat-stroked-button>
      <mat-icon style="margin-right: 5px;">email</mat-icon>apps@c2dev.co.uk
    </button>
  </a>
  <p style="margin-top: 2em;">
    If you would like more information about Supporting African Maths
    Initiatives (SAMI), please visit us at:
  </p>
  <a href="https://samicharity.co.uk" target="_blank" rel="noopener noreferrer"
    ><button mat-stroked-button>
      <mat-icon style="margin-right: 5px;">public</mat-icon>samicharity.co.uk
    </button></a
  >
</div>
