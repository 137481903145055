<mat-form-field style="max-width: 100px">
  <mat-select
    [value]="languageService.activeLanguage$ | async"
    (selectionChange)="selectionChanged($event)"
  >
    <mat-option
      *ngFor="let languageCode of languagesCodes"
      [value]="languageCode"
    >
      {{ languageLabels[languageCode] }}
    </mat-option>
  </mat-select>
</mat-form-field>
