<div
  class="problems-list-container"
  [@fadeChildren]
  *ngIf="problemService.problems$ | async as problems"
>
  <mat-card
    class="problem-card"
    [routerLink]="[problem.slug]"
    *ngFor="let problem of problems"
    [attr.aria-label]="problem.slug"
  >
    <h2 class="problem-title">{{ problem.title }}</h2>
    <div class="featured-problem" *ngIf="problem.featured">Weekly Problem</div>
    <div
      class="problem-image"
      [style.background-image]="
        'url(/assets/maths-club-pack/cover_images/' + problem.slug + '.svg)'
      "
    ></div>
  </mat-card>
</div>
