<div
  class="markdown-container"
  *ngIf="problemService.activeProblem$ | async as problem"
>
  <div [@fadeInOut]="markdownReady ? 'in' : 'out'">
    <markdown
      id="markdownStyle"
      [data]="problem.facilitatorVersionText"
      katex
      (ready)="onMarkdownReady()"
    ></markdown>
  </div>
</div>
